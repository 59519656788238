<template>
  <v-container fluid>
    <v-expansion-panels>
      <type-filter />
      <grade-filter />
    </v-expansion-panels>
  </v-container>
</template>
<script>
import GradeFilter from "./GradeFilter.vue";
import TypeFilter from "./TypeFilter.vue";

export default {
  components: {
    GradeFilter,
    TypeFilter
  }
};
</script>
