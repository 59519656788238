<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="d-flex justify-space-between">
      <div>Mother/Father</div>
      <div>
        <v-chip v-if="type" color="primary" @click:close="clearFilter" close>{{
          type
        }}</v-chip>
      </div>
      <v-spacer />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container fluid>
        <v-row class="d-flex justify-start">
          <v-select
            @input="changed"
            label="Type"
            chips
            clearable
            :items="items"
            v-model="type"
            class="flex-grow-0"
          ></v-select>
          <v-spacer class="flex-grow-1" />
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      items: ["mother", "father"],
      type: null,
      enabled: true
    };
  },
  methods: {
    changed() {
      const type = this.type;
      if (!type) {
        this.clearFilter();
        return;
      }
      const filter = {
        type: "type",
        filterFunc: function(val) {
          return val.type === type;
        },
        info: `type: ${type}`
      };
      this.$store.commit("ADD_CHANGE_FILTER", filter);
    },
    clearFilter() {
      this.type = null;
      this.$store.commit("CLEAR_FILTER", "type");
    }
  }
};
</script>
