<template>
  <v-container>
    <v-row>
      <h3>Filters:</h3>
    </v-row>
    <v-row>
      <filters />
    </v-row>
    <v-row>
      <h3>Included:</h3>
    </v-row>
    <v-row>
      <subscriber-list
        :subs="selectedSubscribers"
        @item-click="addToExcluded"
        icon="mdi-account-remove"
      />
    </v-row>
    <template v-if="excludedSubscribers.length > 0">
      <v-row>
        <h3>Excluded:</h3>
      </v-row>
      <v-row>
        <subscriber-list
          :subs="excludedSubscribers"
          @item-click="removeFromExcluded"
          icon="mdi-account-plus"
        />
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Filters from "./Filters";
import SubscriberList from "./SubscriberList";
export default {
  components: {
    Filters,
    SubscriberList
  },
  methods: {
    addToExcluded(item) {
      if (item.email && item.email[0])
        this.$store.commit("ADD_TO_EXCLUDED", item.email[0]);
    },
    removeFromExcluded(item) {
      this.$store.commit("REMOVE_FROM_EXCLUDED", item.email[0]);
    }
  },
  computed: {
    ...mapGetters(["selectedSubscribers", "excludedSubscribers"])
  },
  mounted() {
    this.$store.dispatch("getSubscribers");
  }
};
</script>
<style scoped>
h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: normal;
}
</style>
