<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="subs" item-key="name">
      <template v-slot:item.action="{ item }">
        <v-icon class="mr-2" @click="$emit('item-click', item)">
          {{ icon }}
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Family",
          align: "left",
          value: "familyName"
        },
        { text: "Last Name", value: "lastName" },
        { text: "First Name", value: "firstName" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "action", sortable: false }
      ]
    };
  },
  props: {
    subs: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String
    }
  }
};
</script>
