<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="d-flex justify-space-between">
      <div>Grades</div>
      <div>
        <v-chip
          class="upchip"
          @click:close="removeGrade(c.id, c.complete)"
          v-for="c in upperChips"
          :key="c.id"
          color="primary"
          close
          >{{ c.cname }}</v-chip
        >
      </div>
      <v-spacer />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container fluid>
        <v-chip-group multiple active-class="primary--text">
          <v-chip
            :input-value="allPreschool"
            @input="toggleStage('Preschool', $event)"
            >Preschool</v-chip
          >
          <v-chip
            :input-value="allElementary"
            @input="toggleStage('Elementary', $event)"
            >Elementary</v-chip
          >
          <v-chip
            :input-value="allHighSchool"
            @input="toggleStage('High School', $event)"
            >High School</v-chip
          >
        </v-chip-group>
        <v-divider />
        <v-row class="d-flex justify-start wrap">
          <v-chip-group
            column
            v-model="active"
            multiple
            active-class="primary--text"
          >
            <div
              v-for="grade in grades"
              :key="grade[0].pos"
              class="ggroup flex-grow-1"
            >
              <v-chip v-for="g in grade" :key="g.id" filter :value="g.id"
                >{{ g.name }} {{ g.section }}</v-chip
              >
            </div>
          </v-chip-group>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapGetters } from "vuex";
import { differenceWith, difference, union, cloneDeep } from "lodash-es";

export default {
  data() {
    return {
      active: []
    };
  },
  computed: {
    ...mapGetters({ grades: "getByGrade" }),
    allPreschool() {
      return this.allStage("Preschool");
    },
    allElementary() {
      return this.allStage("Elementary");
    },
    allHighSchool() {
      return this.allStage("High School");
    },
    upperChips() {
      const grades = this.$store.state.grades;
      let chips = this.active
        .map(id => grades.find(v => v.id === id))
        .filter(v => !!v)
        .map(c => ({ ...c, cname: `${c.name} ${c.section}` }));
      if (this.allStage("Preschool")) {
        chips = chips
          .filter(c => c.stage != "Preschool")
          .concat([{ id: "Preschool", cname: "Preschool", complete: true }]);
      }
      if (this.allStage("Elementary")) {
        chips = chips
          .filter(c => c.stage != "Elementary")
          .concat([{ id: "Elementary", cname: "Elementary", complete: true }]);
      }
      if (this.allStage("High School")) {
        chips = chips
          .filter(c => c.stage != "High School")
          .concat([
            { id: "High School", cname: "High School", complete: true }
          ]);
      }
      return chips;
    }
  },
  watch: {
    active(val) {
      if (val.length === 0) {
        this.$store.commit("CLEAR_FILTER", "grade");
      } else {
        const grades = cloneDeep(this.active);
        const filter = {
          type: "grade",
          filterFunc: function(parent) {
            const parentGrades = parent.grades || [];
            return parentGrades.some(pg => grades.includes(pg.id));
          },
          info: `grades: ` + this.upperChips.map(uc => uc.cname).join(" ")
        };
        this.$store.commit("ADD_CHANGE_FILTER", filter);
      }
    }
  },
  methods: {
    allStage(stage) {
      const grades = this.$store.getters.gradesByStage[stage];
      return (
        grades &&
        differenceWith(grades, this.active, (a, v) => a.id === v).length === 0
      );
    },
    toggleStage(stage, ev) {
      const ids = this.$store.getters.gradesByStage[stage].map(g => g.id);
      if (ev === false) {
        this.active = difference(this.active, ids);
      } else if (ev === true) {
        this.active = union(this.active, ids);
      }
    },
    removeGrade(id, complete) {
      if (complete) {
        this.toggleStage(id, false);
      } else {
        const idx = this.active.indexOf(id);
        if (idx !== -1) {
          this.active.splice(idx, 1);
        }
      }
    }
  }
};
</script>
<style scoped>
.ggroup {
  margin-left: 0.7em;
}
.upchip {
  margin-right: 0.5em;
}
</style>
