<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1"
        >Select Recipients</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 2" step="2"
        >Select Template</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step step="3">Send</v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <recipients />
        <div class="step-buttons">
          <v-btn color="primary" @click="e1 = 2">Continue</v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="2">
        <compose-email @update="updateEmail" @changeStep="e1 += $event" />
      </v-stepper-content>
      <v-stepper-content step="3">
        <send-review :message="message" @changeStep="e1 += $event" />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import Recipients from "../components/Recipients";
import ComposeEmail from "../components/Compose";
import SendReview from "../components/SendReview";

export default {
  components: {
    Recipients,
    ComposeEmail,
    SendReview
  },
  data() {
    return {
      e1: 1,
      message: { apimsg: { subject: "" } }
    };
  },
  methods: {
    updateEmail(email) {
      this.message = email;
    }
  }
};
</script>
<style>
.step-buttons {
  text-align: right;
  margin-right: 2em;
}
.step-buttons button {
  margin-right: 1em;
}
</style>
