<template>
  <div>
    <v-container class="d-flex flex-column">
      <div class="d-flex">
        <v-col class="px-0" sm="12" md="6" lg="5">
          <v-select
            dense
            :items="addresses"
            v-model="from"
            item-text="name"
            return-object
            outlined
            label="From"
          ></v-select>
        </v-col>
      </div>
      <v-checkbox
        v-model="header"
        label="Incluir header Colegio Jefferson"
      ></v-checkbox>
      <v-checkbox v-model="includeText" label="Incluir texto"></v-checkbox>
      <quill v-if="includeText" @html="textHtml = $event" v-model="text" />
      <v-text-field dense outlined v-model="subject" label="Subject" />
      <v-checkbox v-model="includeImages" label="Incluir Imagenes"></v-checkbox>
      <div class="d-flex">
        <file-pond
          class="flex-grow-1"
          v-model="images"
          v-show="includeImages"
          name="pImages"
          ref="pImages"
          :server="server"
          :allow-multiple="true"
          :allow-image-preview="true"
          :allow-image-exif-orientation="true"
          accepted-file-types="image/jpeg, image/png"
        />
      </div>
      <v-checkbox
        v-model="includeAttachments"
        label="Incluir Attachments"
      ></v-checkbox>
      <div class="d-flex">
        <file-pond
          class="flex-grow-1"
          :server="server"
          v-show="includeAttachments"
          name="PAttachments"
          ref="pAttachments"
          v-model="attachments"
          :allow-multiple="true"
          :allow-image-preview="false"
          accepted-file-types="image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
        />
      </div>
    </v-container>
    <div class="step-buttons">
      <v-btn color="primary" @click="$emit('changeStep', -1)">Back</v-btn>
      <v-btn
        color="primary"
        :disabled="!isValid"
        :loading="wait"
        @click="nextStep()"
        >Continue</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { isNil, cloneDeep } from "lodash-es";
import Quill from "./quill";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

const isNotEmpty = v =>
  !isNil(v) && typeof v === "string" && v.trim().length > 0;

export default {
  components: {
    Quill,
    FilePond
  },
  data() {
    return {
      continue: false,
      subject: "",
      postText: "",
      from: null,
      header: false,
      text: {},
      textHtml: null,
      images: null,
      wait: false,
      attachments: null,
      includeText: false,
      includeImages: false,
      includeAttachments: false,
      server: {
        url: process.env.VUE_APP_API_URL,
        process: "/process",
        revert: "/files",
        fetch: null,
        load: null
      }
    };
  },
  methods: {
    nextStep() {
      let p = [];
      if (this.includeImages) {
        p = [this.$refs.pImages.processFiles()];
      }
      if (this.includeAttachments) {
        p.push(this.$refs.pAttachments.processFiles());
      }
      if (p.length > 0) {
        this.wait = true;
      }
      Promise.all(p)
        .then(() => {
          const emailBody = {
            subject: this.subject,
            text: this.textHtml,
            from: this.from,
            header: this.header
          };
          if (this.includeAttachments) {
            emailBody.attachments = this.attachments.map(val => ({
              id: val.serverId,
              filename: val.filename
            }));
          }

          if (this.includeImages) {
            emailBody.images = this.images.map(val => val.serverId);
          }

          this.$store.commit("SET_EMAIL_BODY", cloneDeep(emailBody));
          this.$emit("changeStep", 1);
        })
        // .catch(err => {
        //  // TODO
        // })
        .finally(() => {
          this.wait = false;
        });
    }
  },
  computed: {
    ...mapState(["addresses"]),
    isValid() {
      return !isNil(this.from) && isNotEmpty(this.subject);
    }
  }
};
</script>
<style>
.filepond--item {
  width: calc(24% - 0.5em);
}
</style>
