import Vue from "vue";
import Vuex from "vuex";
import client from "../httpclient";
import { groupBy, sortBy, reverse, differenceWith } from "lodash-es";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentFilters: [],
    grades: [],
    subscribers: [],
    excluded: [],
    addresses: [
      { name: "Eventos Jefferson", email: "eventos@colegiojefferson.com" },
      { name: "Administracion Jefferson", email:"admin@colegiojefferson.com" },
      { name: "Admision Colegio Jefferson", email: "admision@colegiojefferson.com" },
      { name: "Evaluacion Colegio Jefferson", email: "evaluacion@colegiojefferson.com" },
    ],
    emailBody: {}
  },
  getters: {
    getByGrade(state) {
      const g = groupBy(state.grades, "pos");
      const pos = sortBy(Object.keys(g), Number);
      return pos.map(k => reverse(sortBy(g[k], "section")));
    },
    gradesByStage(state) {
      return groupBy(state.grades, "stage");
    },
    selectedSubscribers(state) {
      const subscribers = state.subscribers;
      const filtered = state.currentFilters.reduce((subs, filter) => {
        return subs.filter(filter.filterFunc);
      }, subscribers);
      return differenceWith(filtered, state.excluded, (a, v) =>
        a.email ? a.email.includes(v) : false
      );
    },
    excludedSubscribers(state) {
      const addData = v =>
        state.subscribers.find(s => (s.email ? s.email.includes(v) : false));
      return state.excluded.map(addData).filter(v => !!v);
    }
  },
  mutations: {
    ADD_CHANGE_FILTER(state, filter) {
      const idx = state.currentFilters.findIndex(f => f.type == filter.type);
      if (idx === -1) {
        state.currentFilters.push(filter);
      } else {
        state.currentFilters.splice(idx, 1, filter);
      }
    },
    CLEAR_FILTER(state, filter) {
      state.currentFilters = state.currentFilters.filter(
        v => v.type !== filter
      );
    },
    SET_EMAIL_BODY(state, emailBody) {
      state.emailBody = emailBody;
    },
    SET_GRADES(state, grades) {
      state.grades = grades;
    },
    SET_SUBSCRIBERS(state, subscribers) {
      state.subscribers = subscribers;
    },
    ADD_TO_EXCLUDED(state, email) {
      state.excluded.push(email);
    },
    REMOVE_FROM_EXCLUDED(state, email) {
      const idx = state.excluded.indexOf(email);
      if (idx != -1) {
        state.excluded.splice(idx, 1);
      }
    }
  },
  actions: {
    async getAllGrades({ commit }) {
      const grades = await client.get("grades").json();
      commit("SET_GRADES", grades);
    },
    async getSubscribers({ commit }) {
      const parents = await client.get("parents").json();
      commit("SET_SUBSCRIBERS", parents);
    }
  },
  modules: {}
});
