<template>
  <div>
    <v-container class="d-flex flex-column justify-space-between align-start">
      <h3 class="mb-6">Please Review</h3>
      <p>Sending "{{ subject }}" to {{ selectedSubscribers.length }} emails</p>

      <div>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">Test Email</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Send Email To</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row v-if="!finishDialog">
                  <v-col cols="12">
                    <v-text-field
                      label="Email Address"
                      type="email"
                      v-model="emailAddress"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    {{ infoDialog }} <v-icon>{{ eIcon }}</v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="mr-4">
              <template v-if="!finishDialog">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  @click="sendTestEmail"
                  :loading="bLoading"
                  >Send</v-btn
                >
              </template>
              <template v-else>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="okClose">Ok</v-btn>
              </template>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-dialog v-model="eDialog" persistent max-width="400">
        <v-card>
          <v-card-text
            >{{ infoDialog }} <v-icon>{{ eIcon }}</v-icon></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="Primary" @click="finishStepper">ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="step-buttons">
      <v-btn color="primary" @click="$emit('changeStep', -1)">Back</v-btn>
      <v-btn color="primary" :loading="bLoading" @click="sendAllEmails">
        <v-icon left>mdi-email</v-icon>Email
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { sendEmail } from "../emails";
import { cloneDeep, get } from "lodash-es";
export default {
  computed: {
    ...mapGetters(["selectedSubscribers"]),
    ...mapState({ email: "emailBody" }),
    subject() {
      return get(this.email, "subject", "");
    }
  },
  data() {
    return {
      emailAddress: "",
      dialog: false,
      finishDialog: false,
      infoDialog: "",
      eDialog: false,
      eIcon: "mdi-email-check",
      bLoading: false
    };
  },
  methods: {
    sendTestEmail() {
      const email = cloneDeep(this.email);
      this.bLoading = true;
      email.to = [
        {
          name: "",
          email: this.emailAddress.trim()
        }
      ];
      sendEmail(email)
        .then(() => {
          this.eIcon = "mdi-email-check";
          this.infoDialog = "Email Sent";
        })
        .catch(() => {
          this.eIcon = "mdi-alert-circle";
          this.infoDialog = "Error Sending";
        })
        .finally(() => {
          this.finishDialog = true;
          this.bLoading = false;
        });
    },
    finishStepper() {
      this.eDialog = false;
      if (this.eIcon != "mdi-alert-circle") {
        window.location.reload();
      }
    },
    okClose() {
      this.emailAddress = "";
      this.dialog = false;
      this.finishDialog = false;
    },
    sendAllEmails() {
      const email = cloneDeep(this.email);
      this.bLoading = true;
      const addresses = this.selectedSubscribers
        .filter(p => p.email && p.email.length > 0)
        .map(p => ({
          name: `${p.firstName} ${p.lastName}`,
          email: cloneDeep(p.email)
        }));
      email.to = addresses;
      sendEmail(email)
        .then(() => {
          this.eIcon = "mdi-email-check";
          this.infoDialog = "Email Sent";
          this.eDialog = true;
        })
        .catch(() => {
          this.eIcon = "mdi-alert-circle";
          this.infoDialog = "Error Sending";
        })
        .finally(() => {
          this.bLoading = false;
          this.eDialog = true;
          this.eDialog = true;
        });
    }
  }
};
</script>
