<template>
  <div class="editor mb-5">
    <div ref="editor"></div>
  </div>
</template>
<script>
import Quill from "quill";
import { cloneDeep } from "lodash-es";

export default {
  data() {
    return {
      editor: null
    };
  },
  props: ["config", "value"],
  mounted() {
    let config = cloneDeep(this.config) || {};
    config.theme = "snow";
    config.bounds = this.$refs.editor;
    config.modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ size: ["small", false, "large", "huge"] }, "link", { align: [] }]
      ]
    };
    let quill = new Quill(this.$refs.editor, config);
    if (this.value) {
      quill.setContents(this.value);
    }
    quill.on("text-change", () => {
      this.$emit("input", quill.getContents());
      this.$emit("html", quill.root.innerHTML);
    });
    this.editor = quill;
  }
};
</script>
<style scoped>
.editor {
  min-width: 100px;
  width: 100%;
}
.editor div {
  min-height: 200px;
}
</style>
